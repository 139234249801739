<template>
  <b-sidebar
    id="add-new-permission-sidebar"
    :visible="isAddNewPermissionSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-permission-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Permission
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Permission Name"
            rules="required|min:3|max:100|regex:^([a-zA-Z\-]+ )*[a-zA-Z\-]+$"
          >
            <b-form-group
              label="Permission Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="permissionData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/countries-data.js'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPermissionSidebarActive',
    event: 'update:is-add-new-permission-sidebar-active',
  },
  props: {
    isAddNewPermissionSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankPermissionData = {
      name: '',
      full_name: '',
      domains: '',
      website: '',
      industry: '',
      country: '',
    }

    const permissionData = ref(JSON.parse(JSON.stringify(blankPermissionData)))
    const resetpermissionData = () => {
      permissionData.value = JSON.parse(JSON.stringify(blankPermissionData))
    }

    const onSubmit = () => {
      store.dispatch('app/addPermission', permissionData.value)
        .then(response => {
          emit('refetch-data')
          emit('update:is-add-new-permission-sidebar-active', false)
          
          router.push({ name: 'permissions-view', params: { id: response.data.results[0].id } })
        })
        .catch(error => {
          if(typeof error.response === 'undefined') {
            //log error
            console.error(error)
          } else {
            //alert user to error
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error creating new permission.',
                text: 'ERROR: ' + error.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            console.log(error.response.data.message)
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetpermissionData)

    return {
      permissionData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-permission-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
